import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { ReactComponent as TrashIcon } from '../../assets/images/trash-alt.svg';

const RadioGroupField = React.memo(
  ({ input, label, handleDelete, meta: { touched, error }, items, color, labelIcon, ...rest }) => {
    const deletable = typeof handleDelete === 'function';
    const onDelete = id => {
      handleDelete(id);
    };
    return (
      <FormControl component="fieldset" error={!!error && touched}>
        <Grid container direction='row' alignItems='center' spacing={1}>
          {labelIcon && 
          <Grid item>
            {labelIcon}
          </Grid>
          }
          <Grid item>
            <Typography style={{color: 'rgba(0, 0, 0, 0.54)'}}>{label}</Typography>
          </Grid>
        </Grid>
        <RadioGroup
          aria-label={label}
          {...rest}
          {...input}
          value={rest.value || input.value || ''}
          onChange={input.onChange}
        >
          {items.map((item, index) => (
            <div className="d-flex" key={item.value} style={{alignItems: 'center'}}>
              <FormControlLabel style={{margin: 0}} value={item.value} control={<Radio checked = {input.value === item.value} color={color} style={{paddingLeft: 0}}/>} label={item.label} />
              {deletable && (
                <IconButton
                  type="button"
                  disableRipple
                  // hidden={index === items.length - 1}
                  className="button red icon-button"
                  onClick={() => onDelete(item.value)}
                >
                  <Typography style={{color: '#fff', fontWeight: 700}}>-</Typography>
                </IconButton>
              )}
            </div>
          ))}
        </RadioGroup>
        <FormHelperText>{!!error && touched ? error : ''}</FormHelperText>
      </FormControl>
    );
  },
);

RadioGroupField.defaultProps = {
  meta: {
    touched: false,
    error: '',
  },
  input: {},
  items: [],
  color: 'primary',
  handleDelete: null,
};

RadioGroupField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object,
  handleDelete: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  color: PropTypes.string,
};

export default RadioGroupField;
